import { Box, Divider, Link, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { AboutUs } from "../components/AboutUs";
import { ContactUs } from "../components/ContactUs";
import { Footer } from "../components/Footer";
import { HighPetition } from "../components/HighPetition";
import { Meta } from "../components/Meta";
import { OtherPetitions } from "../components/OtherPetitions";
import { PetitionFinishedMessage } from "../components/PetitionDetail/PetitionFinishedMessage";
import { SomeOfOurAchievements } from "../components/SomeOfOurAchievements";
import { PetitionProvider } from "../hooks/usePetition";
import { SignatureProvider } from "../hooks/useSignature";
import { getAllPetitions } from "../lib/getPetition";
import { getAllVictories } from "../lib/getVictory";

export default function Home({ victories, petitions }) {
  const petitionsAvailableToSignIn = petitions.filter(
    (item) => item.is_active === true
  );
  const highPetition = petitionsAvailableToSignIn[0];

  return (
    <>
      <Meta
        title={"Petições"}
        description={
          "IPCO em Ação - Petições do Intituto Plinio Corrêa de Oliveira"
        }
        socialTitle={"Petições do Instituto Plinio Corrêa de Oliveira"}
        pageType={"website"}
        image={"https://campanhas.ipco.org.br/Logo-IPCO.png"}
        createdAt={petitionsAvailableToSignIn[0]?.created_at}
      />

      <main>
        {highPetition !== undefined ? (
          <SignatureProvider>
            <PetitionProvider>
              <HighPetition
                id={highPetition?.id}
                title={highPetition?.title}
                description={highPetition?.description}
                petition_text={highPetition?.petition_text}
                subscribers={highPetition?.subscribers}
                goals={highPetition?.goals}
                show_goal={highPetition?.show_goal}
                created_at={highPetition?.created_at}
                thumbnail={highPetition?.thumbnail}
                thumbnail_url={highPetition?.thumbnail_url}
                thumbnail_placeholder={highPetition?.thumbnail_placeholder}
                is_active={highPetition?.is_active}
                hashtags={highPetition?.hashtags}
                social_title={highPetition?.social_title}
                social_description={highPetition?.social_description}
                slug={highPetition?.slug}
              />
            </PetitionProvider>
          </SignatureProvider>
        ) : (
          <Box py={"4rem"} w='100%' maxW={"600px"} m={"0 auto"}>
            <PetitionFinishedMessage />

            <Divider my='1rem' borderColor='gray.400' />
            <Text mt='1rem' fontSize='14px' textAlign='center'>
              Nota: Ao confirmar, você aceita receber atualizações do IPCO.
              <br />
              Você pode cancelar sua inscrição a qualquer momento.
              <br />
              <NextLink href='/politica-de-privacidade' passHref>
                <Link color='redBrand.500'>Política de Privacidade</Link>
              </NextLink>
            </Text>
          </Box>
        )}
      </main>

      <OtherPetitions data={petitions} highPetition={highPetition} />
      <AboutUs />
      <SomeOfOurAchievements data={victories} />
      <ContactUs />
      <Footer />
    </>
  );
}

export async function getStaticProps() {
  const petitions = await getAllPetitions();
  const victories = await getAllVictories();

  return {
    props: {
      ...petitions,
      ...victories,
    },
    revalidate: 5,
  };
}
