// import { Button, IconButton } from "@chakra-ui/button";
import { Box, Container, Divider, Flex, Grid } from '@chakra-ui/react';
import Image from "next/image";
import styles from "../../../styles/components/OtherPetitions.module.css";
import { MyHeading } from "../MyChackra/MyHeading";
import { PetitionBox } from "./PetitionBox";

export function OtherPetitions(props) {
  const otherPetitions = props.data
  return (<Container maxW="1090px" as={"section"}>
    <MyHeading
      fontWeight="300"
      display="flex"
      flexDirection="row"
      mt="3rem"
      fontFamily="Rasa"
    >
      <Flex flexDirection="row" alignItems="center">
        <Box mr="0.5rem" className={styles.Bookmark}>
          <Image
            src="/bookmark.svg"
            layout="responsive"
            width={53}
            height={53}
            alt="Bookmark"
          />
        </Box>
        Outras petições
      </Flex>
    </MyHeading>
    <Divider borderColor="black" mt={2.5} mb={6} w="100%" />
    <Box className={styles.Slider}>
      <Box className={styles.SlideTrack}>
        <Grid className={styles.Slide} gap={6}>
          {otherPetitions.map((item, index) => {
            //Skip the high petition
            if (props.highPetition !== undefined && item.id === props.highPetition.id) {
              return
            }

            const reacheadGoal = item.goals.filter(item => item.is_reached === false)
            const currentGoal = reacheadGoal[0]?.goal

            return <PetitionBox
              key={index}
              thumbnail={item.thumbnail}
              title={item.title}
              slug={item.slug}
              signatures={item.subscribers}
              percent={item.subscribers / currentGoal * 100}
              isActive={item.is_active}
              thumbnailPlaceholder={item.thumbnail_placeholder}
              className={styles.Petition}
            />
          })}
        </Grid>
      </Box>
    </Box>

    {/* <Box className={styles.PaginationButtons}>
        <Flex
          flexDirection="row"
          mt="3rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            color="redBrand.500"
            fontWeight="500"
            fontSize="23px"
            fontFamily="Roboto"
            fontStyle="normal"
            width="150px"
            textAlign="center"
            ml="4rem"
            variant="link"
            isDisabled
          >
            Recentes
          </Button>
          <Box>
            <Box as="button" p="0" m="0" ml="0.5rem" w={6} h={6} colorScheme="blue" backgroundColor="blueBrand.500" borderRadius="100%" _hover={{ bgColor: "blueBrand.500" }} _active={{ bgColor: "blueBrand.500" }} _focus={{ bgColor: "blueBrand.500" }} />
            <Box as="button" p="0" m="0" ml="0.5rem" w={6} h={6} colorScheme="blue" backgroundColor="#E5E5E5" borderRadius="100%" _hover={{ bgColor: "blueBrand.500" }} _active={{ bgColor: "blueBrand.500" }} _focus={{ bgColor: "blueBrand.500" }} />
            <Box as="button" p="0" m="0" ml="0.5rem" w={6} h={6} colorScheme="blue" backgroundColor="#E5E5E5" borderRadius="100%" _hover={{ bgColor: "blueBrand.500" }} _active={{ bgColor: "blueBrand.500" }} _focus={{ bgColor: "blueBrand.500" }} />
            <Box as="button" p="0" m="0" ml="0.5rem" w={6} h={6} colorScheme="blue" backgroundColor="#E5E5E5" borderRadius="100%" _hover={{ bgColor: "blueBrand.500" }} _active={{ bgColor: "blueBrand.500" }} _focus={{ bgColor: "blueBrand.500" }} />
            <Box as="button" p="0" m="0" ml="0.5rem" w={6} h={6} colorScheme="blue" backgroundColor="#E5E5E5" borderRadius="100%" _hover={{ bgColor: "blueBrand.500" }} _active={{ bgColor: "blueBrand.500" }} _focus={{ bgColor: "blueBrand.500" }} />
          </Box>
          <Button
            color="redBrand.500"
            fontWeight="500"
            fontSize="23px"
            fontFamily="Roboto"
            fontStyle="normal"
            width="150px"
            textAlign="center"
            mr="4rem"
            variant="link"
          >
            Anteriores
          </Button>
        </Flex>
      </Box> */}
  </Container>)
}