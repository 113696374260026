import Image from "next/image"

import {Box, Link} from '@chakra-ui/react'

import styles from "../../../styles/components/Map.module.css"

import mapPic from "../../../public/map.png";

export function Map() {
    const mapSource = "https://www.google.com/maps/place/Plinio+Corr%C3%AAa+de+Oliveira+Institute/@-23.5452648,-46.6581858,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce5839f4cf9a2d:0xba823ee70487eca1!8m2!3d-23.5451804!4d-46.6559574"

    return (
        <Box className={styles.MapContainer}>
            <Link href={mapSource} isExternal>
                <Image
                    width={"528"}
                    height={"300"}
                    src={mapPic}
                    alt={"Localização do Instituto Plínio Corrêa de Oliveira"}
                    placeholder={"blur"}
                    blurDataURL={mapPic}
                    quality={75}
                    loading={"lazy"}
                />
            </Link>
        </Box>
    )
}