import { Box, Flex, Grid, Spacer, Text } from '@chakra-ui/react';
import Image from "next/image";
import { ContactTag } from "./ContactTag";

import styles from "../../../styles/components/DataContainer.module.css";

export function DataContainer() {
    return (
        <Box
            backgroundColor="blueBrand.500"
            h="300px"
            className={styles.DataContainerContainer}
        >
            <Flex
                w="300px"
                maxW="100%"
                h="100%"
                margin="0 auto"
                flexDirection="column"
                className={styles.DataContainerBox}
            >
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <Image
                        src="/google-maps-logo.svg"
                        width={82}
                        height={82}
                        alt="Logo do Google Maps"
                    />
                    <Box w="180px">
                        <Text fontSize="15" fontFamily="Rasa" textTransform="uppercase" color="white">
                            Rua Maranhão, 341, Bairro Higienópolis, São Paulo – SP, CEP 01240-001
                        </Text>
                    </Box>
                </Grid>
                <ContactTag />
                <Spacer />
            </Flex>
        </Box>
    )
}