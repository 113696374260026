import { Button } from "@chakra-ui/button";
import { Box, Flex, LinkBox, LinkOverlay, Skeleton, Spacer, Stack, Text } from '@chakra-ui/react';
import buildUrl from "cloudinary-build-url";
import Image from "next/image";
import NextLink from "next/link";


export function PetitionBox(props) {
    const url = buildUrl(props.thumbnail, {
        cloud: {
            cloudName: 'ipcocloud'
        }
    });

    return (<LinkBox as="article">
        <Flex
            w="225px"
            h="371px"
            bgColor="#E5E5E5"
            borderRadius="5"
            flexDirection="column"
        >
            <Box
                width={"225"}
                height={"150"}
            >
                <Image
                    src={url}
                    alt={`Caapa da petição: ${props.title}`}
                    width={225}
                    height={150}
                    placeholder={"blur"}
                    blurDataURL={props.thumbnailPlaceholder}
                    loading={"lazy"}
                />
            </Box>
            <Box
                h="20px"
                w={`${props.percent}%`}
                maxW="100%"
                borderRadius={props.percent >= 100 ? "0" : "0 5px 5px 0"}
                bgColor={!props.percent ? "gray.base" : "yellowBrand.500"}
                m="0"
                p="0"
            />
            <Flex
                w="100%"
                borderColor="black"
                border="1px"
                borderLeft="0px"
                borderRight="0px"
                p="0"
                px="0.5rem"
                flexDirection="row"
            >
                <Image
                    src="/person.svg"
                    width="14"
                    height="14"
                    alt="Ícone de pessoas"
                />
                <Text
                    color="redBrand.500"
                    textTransform="uppercase"
                    fontSize="10px"
                    fontFamily="Roboto"
                    display={"flex"}
                    flexDir={"row"}
                    alignItems={"center"}
                >
                    {!props.signatures ? <Skeleton h={3} w={10} m={1} /> : props.signatures} assinaturas
                </Text>
            </Flex>
            <Box
                fontFamily="Roboto"
                fontSize="14px"
                fontWeight="900"
                textTransform="uppercase"
                lineHeight="16px"
                fontStyle="normal"
                p={5}
            >
                <NextLink href={`/peticoes/${props.slug}`} passHref>
                    <LinkOverlay>
                        {!props.title ? <Stack>
                            <Skeleton h={5} w={"100%"} />
                            <Skeleton h={5} w={"100%"} />
                            <Skeleton h={5} w={"70%"} />
                        </Stack> : props.title}
                    </LinkOverlay>
                </NextLink>
            </Box>
            <Spacer />

            <NextLink href={`/peticoes/${props.slug}`} passHref>
                <LinkOverlay href={`/peticoes/${props.slug}`}>
                    <Button
                        colorScheme={props.isActive === true ? "blue" : "red"}
                        backgroundColor={props.isActive === true ? "blueBrand.500" : "redBrand.500"}
                        textTransform="uppercase"
                        w="100%"
                        h="34px"
                        borderRadius="5"
                        isLoading={!props.title}
                    >
                        {props.isActive === true ? "Assine Agora" : "Finalizada"}
                    </Button>
                </LinkOverlay>
            </NextLink>
        </Flex>
    </LinkBox>)
}