import { Box, Container, Grid } from '@chakra-ui/react';
import { MyHeading } from "../MyChackra/MyHeading";
import { AchievementBox } from "./AchievementBox";

import styles from "../../../styles/components/SomeOfOurAchievements.module.css";

export function SomeOfOurAchievements(props) {
    return (
        <Container maxW="full" bgColor="yellowBrand.500" mt="0" id="nossas-conquistas" as={"section"}>
            <Container p="0" maxW="1090px" margin="0 auto" py="2rem">
                <MyHeading color="blueBrand.500">
                    Algumas de nossas conquistas
                </MyHeading>

                <Box className={styles.Slider} pt="2rem">
                    <Box className={styles.SlideTrack}>
                        <Grid className={styles.Slide} gap={6}>
                            {
                                props.data.map((item) => {
                                    return (
                                        <AchievementBox
                                            key={item.id}
                                            thumbnail={item.thumbnail}
                                            thumbnailPlaceholder={item.thumbnail_placeholder}
                                            description={item.title}
                                            link={`/vitorias/${item.slug}`}
                                        />
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Container>
    )
}