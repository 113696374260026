import { Container, Flex } from '@chakra-ui/react';
import { MyHeading } from "../MyChackra/MyHeading";
import { DataContainer } from "./DataContainer";
import { Map } from "./Map";

import styles from "../../../styles/components/ContactUs.module.css";

export function ContactUs() {
    return (
        <Container maxW="1090px" p="0" pt="3rem" id="fale-conosco" as={"section"}>
            <MyHeading color="blueBrand.500" mb="1rem" pl="1rem">
                Fale Conosco
            </MyHeading>
            <Flex className={styles.ContactUsContainer}>
                <Map />
                <DataContainer />
            </Flex>
        </Container>
    )
}