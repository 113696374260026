import dynamic from "next/dynamic";
import Image from "next/image";

import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Heading,
    Link,
    LinkBox,
    LinkOverlay,
    Skeleton,
    Stack,
    Text
} from "@chakra-ui/react";
import { useEffect } from "react";
import { MyHeading } from "../MyChackra/MyHeading";
import { ThanksForSignin } from "../ThanksForSignIn";

import { usePetition } from '../../hooks/usePetition';

import buildUrl from "cloudinary-build-url";
import NextLink from "next/link";
import personPic from "../../../public/person.svg";
import styles from "../../../styles/components/HighPetition.module.css";
import { useSignature } from "../../hooks/useSignature";

const DynamicShareComponent = dynamic(() => import('../PetitionShareButtons'))

const DynamicForm = dynamic(() => import('../Form'))

export function HighPetition(props) {
    const { petitionData, setPetitionData, subscribers } = usePetition()
    useEffect(() => {
        setPetitionData(props)
    }, [props, setPetitionData])

    const url = buildUrl(props.thumbnail, {
        cloud: {
            cloudName: "ipcocloud"
        }
    })

    const { isSigned } = useSignature()

    return (<Flex flexDirection="row" maxW="1080" margin="0 auto" pt="2rem">
        <Container
            maxW="1042px"
            textAlign="center"
        >
            <Flex flexDirection="column">
                <Heading
                    as="h2"
                    textTransform="uppercase"
                >
                    Petição em <Text color="blueBrand.500" display="inline">alta</Text>
                </Heading>

                <LinkBox as="card">
                    <Flex pt="2rem" className={styles.HighPetitionBox}>
                        <Box>
                            <Box h={300} w={500} maxW={"100%"}>
                                <Image
                                    src={url}
                                    width={500}
                                    height={300}
                                    alt={`Capa da petição: ${petitionData?.title}`}
                                    placeholder={"blur"}
                                    blurDataURL={props.thumbnail_placeholder}
                                    priority
                                />
                            </Box>
                        </Box>
                        <Divider
                            orientation="vertical"
                            h="200px"
                            marginTop="50px"
                            marginX="15px"
                            borderColor="black"
                            className={styles.VerticalDivider}
                        />
                        {!petitionData.id ? <Stack h={80} w={"100%"} maxW={"500px"} spacing={8}>
                            <Skeleton h={24} />
                            <Stack>
                                <Skeleton h={8} />
                                <Skeleton h={8} />
                                <Skeleton h={8} />
                            </Stack>
                            <Skeleton h={4} />
                        </Stack> : <Flex
                            h="300px"
                            textAlign="left"
                            flexDirection="column"
                            className={styles.TitleBox}
                        >
                            <Heading
                                as="h3"
                                mb="1rem"
                                textTransform="uppercase"
                                fontSize="2xl"
                            >
                                <NextLink
                                    href={!petitionData.slug ? "#" : `/peticoes/${petitionData?.slug}`}
                                    passHref
                                >
                                    <LinkOverlay>
                                        {!petitionData.id ? <Skeleton h={12} w={"90%"} /> : petitionData.title}
                                    </LinkOverlay>
                                </NextLink>
                            </Heading>
                            <Divider
                                orientation="horizontal"
                                w="100%"
                                borderColor="black"
                                mt="-0.5rem"
                                mb="1rem"
                                className={styles.HorizontalDivider}
                            />
                            <Text
                                fontSize="17px"
                                maxH="100px"
                                noOfLines={4}
                                textAlign="justify"
                            >
                                <span dangerouslySetInnerHTML={{
                                    __html: petitionData?.description
                                }}></span>
                            </Text>
                            <Box
                                mt="0.5rem"
                                className={styles.Footer}
                            >
                                <Flex flexDirection="row">
                                    <Image
                                        src={personPic}
                                        width={16}
                                        height={16}
                                        layout="fixed"
                                        alt="Ícone de uma pessoa"
                                    />

                                    <Text color="redBrand.500" textTransform="uppercase" ml="0.3rem" mr="0.3rem"
                                        display={"flex"} flexDir={"row"}>
                                        {subscribers} Assinaturas
                                    </Text> |
                                    <Text color="redBrand.500" textTransform="uppercase" ml="0.3rem">
                                        {petitionData.created_at}
                                    </Text>
                                </Flex>
                                <NextLink
                                    href={!petitionData.slug ? "#" : `/peticoes/${petitionData?.slug}`}
                                    passHref
                                >
                                    <Link>
                                        {!petitionData.id ? <></> : <Button
                                            variant="outline"
                                            colorScheme="black"
                                            textTransform="uppercase"
                                            w="100px"
                                            h="21px"
                                            borderWidth="2px"
                                            fontSize="13px"
                                            fontWeight="normal"
                                            _focus={{
                                                borderWidth: "0px"
                                            }}
                                            className={styles.Button}
                                        >
                                            Saiba Mais
                                        </Button>}
                                    </Link>
                                </NextLink>
                            </Box>
                        </Flex>}
                    </Flex>
                </LinkBox>

            </Flex>

            <Flex mt="2rem" flexDirection="column" alignItems="center">

                {isSigned ? <>
                    <ThanksForSignin />
                    <Alert status="success" variant="left-accent" maxW="400px">
                        <AlertIcon />
                        <AlertDescription>Obrigado por assinar essa petição</AlertDescription>
                    </Alert>
                </> : <Box>
                    <MyHeading>Assine</MyHeading>
                    <DynamicForm />
                    <Text mt="1rem" fontSize="14px">
                        Nota: Ao assinar, você aceita receber atualizações do IPCO.<br />
                        Você pode cancelar sua inscrição a qualquer momento.&nbsp;<br />
                        <NextLink href={"/politica-de-privacidade"} passHref>
                            <Link color="redBrand.500">Política de Privacidade</Link>
                        </NextLink>
                    </Text>
                </Box>}
            </Flex>
        </Container>
        <Flex
            marginTop="0"
            mr={2}
            flexDirection="column"
            justifyContent="space-between"
            h="200px"
        >
            <DynamicShareComponent />
        </Flex>
    </Flex>)
}