import { Box, Container, Text } from '@chakra-ui/react';
import { MyHeading } from "./MyChackra/MyHeading";

export function AboutUs() {
    return (
        <Container maxW="full" bgColor="blueBrand.500" mt="3rem" id="sobre" as={"section"}>
            <Container p="0" maxW="1090px" margin="0 auto" py="2rem">
                <MyHeading color="yellowBrand.500">Sobre nós</MyHeading>
                <Box color="white" fontFamily="Rasa" fontSize="20px" fontWeight="normal">
                    <Text pt="1rem">
                        O Instituto Plinio Corrêa de Oliveira é uma associação civil de inspiração Católica sem fins
                        lucrativos.
                    </Text>
                    <Text pt="0.5rem">
                        Fundado em dezembro de 2006 por discípulos de Plínio Corrêa de Oliveira tem por finalidade
                        defender o Brasil e a Civiização Cristã de um processo de descristianização da sociedade
                    </Text>
                    <Text pt="0.5rem">
                        A Ação Jovem do IPCO é o setor de promoção de campanhas e atividades públicas do Instituto
                        Plinio Corrêa de Oliveira realizadas com o intuito de defender e preservar os pilares básicos da
                        Civilização Cristã
                    </Text>
                </Box>
            </Container>
        </Container>
    )
}