import { Box, Flex, Link } from '@chakra-ui/react';
import Image from "next/image";

import styles from "../../../styles/components/ContactTag.module.css";

export function ContactTag() {
    const data = [
        {
            icon: "telegram-logo.svg",
            iconAlt: "Ícone do Telegram",
            label: "Entre no nosso canal do Telegram",
            link: "https://t.me/canalipco"
        },
        {
            icon: "youtube-logo.svg",
            iconAlt: "Ícone do Youtube",
            label: "Se inscreva no nosso canal do Youtube",
            link: "https://www.youtube.com/user/caravanaipco"
        },
        {
            icon: "gmail-logo.svg",
            iconAlt: "Ícone de E-mail",
            label: "contato@ipco.org.br",
            link: "mailto:contato@ipco.org.br"
        },
    ]
    return (
        <>
            {
                data.map((item, index) => {
                    return (
                        <Box
                            borderRadius="42"
                            backgroundColor="yellowBrand.500"
                            mt="1rem"
                            key={index}
                            className={styles.ContactTagContainer}
                        >
                            <Flex
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Link href={item.link} className={styles.ImageBox} isExternal>
                                    <Box pl="10px" pt="6px" pr={1}>
                                        <Image
                                            src={`/${item.icon}`}
                                            width={30}
                                            height={30}
                                            alt={item.iconAlt}
                                        />
                                    </Box>
                                </Link>
                                <Link href={item.link} className={styles.Label} isExternal>
                                    <Box
                                        bgColor="white"
                                        borderRadius="0 42px 42px 0"
                                        fontFamily="Roboto"
                                        color="blueBrand.500"
                                        fontWeight="medium"
                                        p="0.5rem"
                                        alignItems="center"
                                        d={"flex"}
                                        h="45px"
                                    >
                                        {item.label}
                                    </Box>
                                </Link>
                            </Flex>
                        </Box>
                    )
                })
            }
        </>
    )
}