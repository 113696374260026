import { Box, Link, Skeleton, Stack } from "@chakra-ui/react";
import buildUrl from "cloudinary-build-url";
import Image from "next/image";
import NextLink from "next/link";

export function AchievementBox(props) {
    const url = buildUrl(props.thumbnail, {
        cloud: {
            cloudName: "ipcocloud"
        }
    })

    return (<Box>
        <NextLink href={props.link} passHref>
            <Link>
                <Box
                    w="225px"
                    h="291"
                    border="2px solid white"
                    borderRadius="5"
                    mb="-297px"
                    ml="6px"
                    z-index="-1"
                />
                <Box backgroundColor="blueBrand.500" w="225px" h="291" borderRadius="5">
                    <Box width={"225"} height={"150"}>
                        <Image
                            src={url}
                            width={225}
                            height={150}
                            alt={`Capa da vitória: ${props.description}`}
                            placeholder={"blur"}
                            blurDataURL={props.thumbnailPlaceholder}
                        />
                    </Box>
                    <Box
                        fontFamily="Roboto"
                        fontSize="14px"
                        textAlign="left"
                        fontWeight="bold"
                        textTransform="uppercase"
                        color="white"
                        h="141px"
                        p="1rem"
                    >
                        {!props.description ? <Stack>
                            <Skeleton h={5} w={"100%"} />
                            <Skeleton h={5} w={"100%"} />
                            <Skeleton h={5} w={"70%"} />
                        </Stack> : props.description}
                    </Box>
                </Box>
            </Link>
        </NextLink>
    </Box>)
}